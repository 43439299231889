import React from "react";


import img1 from './assets/Gl/GL2-img1.jpg';
import img2 from './assets/Gl/GL2-img2.jpg';







const GuestPharmaceutics = () => {
    return(
        <div className="container mb-5 mt-5">
            <h1 className="text-custom-green text-center mb-4">
            Guest Lecture Report on Pharmaceutics
            </h1>
            <p className="text-dark">
            On behalf of B R Harne College of Pharmacy, Vangani, a guest lecture on Pharmaceutics was organized on Friday, 09/08/2024. The session was conducted by Mrs. Disha Deokar, Assistant Professor at Konkan Gyanpeeth Rahul Dharkar College of Pharmacy and Research Institute, Karjat.
            </p>
            <li className="fs-4">Objective</li>
            <p className="text-dark">
            The objective of the lecture was to provide an in-depth understanding of pharmaceutics with a special emphasis on its importance for university exams and the GPAT (Graduate Pharmacy Aptitude Test).
            </p>
            <li className="fs-4">Key Highlights</li>
            <p className="text-dark">
            Pharmaceutical Formulation Processes: Mrs. Deokar explained key formulation processes and their importance in developing effective drug delivery systems.
            </p>
            <p className="text-dark">Solubility and Bioavailability Enhancements: The lecture focused on techniques to improve solubility and bioavailability of drugs, which are critical factors in pharmaceutical development.</p>
            <p className="text-dark">University and GPAT Exam Guidance Specific tips and key topics related to pharmaceutics for university and GPAT exams were discussed, helping students focus on essential areas for better preparation.</p>
            
            <li className="fs-4">Learning Outcomes</li>
            <p className="text-dark">
            Students enhanced their knowledge of pharmaceutics, including drug formulation techniques and solubility improvements.
            </p>
            <p className="text-dark">
            They gained valuable insights on preparing for exams, especially in areas critical to pharmaceutics.   
            </p>
            <p className="text-dark">
            The session was highly informative and helped students connect classroom learning to practical applications in the pharmaceutical industry.  
            </p>
            <li className="fs-4">Vote of Thanks</li>
            <p className="text-dark">
            The vote of thanks was delivered by Mrs. Shraddha Khadse, acknowledging Mrs. Deokar for her engaging and insightful lecture.
            </p>



            <div className="row">
                <div className="col-12 col-md-6 mb-4">
                    <img src={img1} className="img-fluid" alt="" />

                </div>
                <div className="col-12 col-md-6 mb-4">
                    <img src={img2} className="img-fluid" alt="" />

                </div>
            </div>
        </div>

    );
};



export default GuestPharmaceutics;