import React from "react";


import img1 from './assets/CSMJ/csmj1.jpeg';
import img2 from './assets/CSMJ/csmj2.jpeg';
import img3 from './assets/CSMJ/csmj3.jpeg';




const Shivevent = () => {
    return(
        <div className="container mb-5 mt-5">
            <h1 className="text-custom-green text-center mb-4">Chatrapati Shivaji maharaj jayanti celebration (19/02/2024)</h1>

            <p className="text-dark">
            Shiv Jayanti, also known as Chhatrapati Shivaji Maharaj Jayanti, is celebrated annually to honor the birth anniversary of the legendary Maratha warrior, Shivaji Maharaj. Born on February 19, 1630, Shivaji Maharaj was a remarkable leader who established the Maratha Empire in the Indian subcontinent, known for his valor, administrative skills, and commitment to justice.
            </p>
            <p className="text-dark">
            The celebration of Shiv Jayanti is not just a mere observance of a historical figure's birth anniversary; it is a commemoration of his ideals and principles. Shivaji Maharaj epitomized courage, righteousness, and love for his people. He fought against the oppressive Mughal and Deccan Sultanate rulers to establish a sovereign Maratha state.
            </p>
            <p className="text-dark">
            shivjayanti was celebrated on 19/02/2024 at B.R.Harne college of pharmacy vangani,celebration we had organized such as lectures on Shivaji Maharaj's life, cultural performances, like lavani ,lezim dance and povada. That time shiv ghoshna was conducted by students.
            </p>

            <div className="row">
                <div className="col-12 col-md-4">
                    <img src={img1} alt="" className="img-fluid"/>


                </div>
                <div className="col-12 col-md-4">
                <img src={img2} alt="" className="img-fluid"/>

                </div>
                <div className="col-12 col-md-4">
                <img src={img3} alt="" className="img-fluid"/>

                </div>
            </div>
        </div>
    );
};




export default Shivevent;