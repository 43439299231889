import React from "react";

import img1 from './assets/fp/fp1.jpeg';
import img2 from './assets/fp/fp2.jpeg';




const Fresher = () => {

    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Fresher's Party (21/10/2023 )

            </h1>
            <p className="text-dark">
            A freshers' party is an introductory celebration aimed at welcoming new students, commonly known as "freshers," to a college or university. It stands as a significant event that marks the beginning of their academic journey in a new environment. This festivity is usually organized by the senior students or sometimes by the institution itself, providing a platform for newcomers to mingle, make friends, and integrate into the campus community.
            </p>

            <p className="text-dark">
                Freshers party was held on 21/10/2023 at B.R.Harne college of pharmacy vangani,this was celebrated for welcoming the new batch of f.y.b.pharm and f.y.d.pharm the programme was started by worshipping loddess saraswati , speech, singing and dancing various games and rampwalk with introduction was organized for new batch students.Mr and miss was declared at the end of programme.
            </p>


            <div className="row">
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default Fresher;