import React from "react";

import img1 from './assets/aids/aid1.jpeg';
import img2 from './assets/aids/aid2.jpeg';
import img3 from './assets/aids/aid3.jpeg';
import img4 from './assets/aids/aid4.jpeg';
import img5 from './assets/aids/aid5.jpeg';
import img6 from './assets/aids/aid6.jpeg';




const WorldAids = () => {

    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            WORLD AID'S DAY CELEBRATION (01/12/2023)

            </h1>
            <p className="text-dark">
            Leprosy Awareness Program conducted by B.R. Harne College of Pharmacy in collaboration with the Maharashtra Government Health Department, Assistant Director of Health Services (Leprosy), Thane.
                
                            </p>

                            <p className="text-dark">
                            Since the first cases of AIDS were reported in the early 1980s, the disease has claimed millions of lives, drastically altering the world's social, cultural, and political landscapes. Initially, the response to the epidemic was marred by fear, stigma, and discrimination, further complicating the efforts to control its spread and to care for those affected. Over the decades, however, significant strides have been made in understanding the virus, leading to advancements in medical treatments that have transformed HIV from a near-certain death sentence into a manageable chronic condition for many.
                            </p>

                            <p className="text-dark">
                            World aids day was celebrated on 1/12/2023 at B.R.Harne college of pharmacy vangani.on these day Awareness and Education programme was conducted .an essay writing competition was organize by the college 1 st and runner up winner were declared.
                            </p>

            <div className="row">
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img2} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img3} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img4} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img5} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img6} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default WorldAids;