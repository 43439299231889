import React, { useState } from "react";


import './Activity.css'

// Import your PDF and component
import rangolipdf from './assets/Rangoli.pdf';
import BAMSpdf from './assets/BAMS Hands on Training FINAL.pdf'

const ActivityList = () => {
  const [filter, setFilter] = useState("all");

  // Define your activities with multiple links for each event
  const activities = [
    {
      date: "14/12/2024",
      event: "Pharmaceutical Practices in Ayurveda: a hands-on Demonstration",
      links: [
        { label: "Read More", url: BAMSpdf}
      ]
    },
    {
      date: "11/10/2024",
      event: "Rangoli & Quiz Competition Report",
      links: [
        { label: "Read More", url: rangolipdf },
      ],
    },
    {
      date: "13/03/2024",
      event: "Blood Donation",
      links: [
        { label: "Read More", url: "/Blooddon" },
      ],
    },
    {
      date: "08/03/2024",
      event: "World Women's Day",
      links: [
        { label: "Read More", url: "/Womens Day" },

      ],
    },
    {
      date: "05/03/2024",
      event: "Cultural Event",
      links: [
        { label: "Read More", url: "/Cultural event" },
      ],
    },
    {
      date: "19/02/2024",
      event: "Shivajayanti Celebration",
      links: [
        { label: "Read More", url: "/Shiv Event" },
      ],
    },
    {
      date: "12/02/2024",
      event: "Leprosy Awareness Program",
      links: [
        { label: "Read More", url: "/leprosy" },
      ],
    },
    {
      date: "30/01/2024",
      event: "World Leprosy Day",
      links: [
        { label: "Read More", url: "/World Leprosy" },
      ],
    },
    {
      date: "01/12/2023",
      event: "World AIDS Day",
      links: [
        { label: "Read More", url: "/World Aids" },
      ],
    },
    {
      date: "24/10/2023",
      event: "Dashera Celebration",
      links: [
        { label: "Read More", url: "/Dashara" },
      ],
    },
    {
      date: "21/10/2023",
      event: "Fresher's Party",
      links: [
        { label: "Read More", url: "/Fresher" },
      ],
    },
    {
      date: "20/10/2023",
      event: "Navratri Celebration",
      links: [
        { label: "Read More", url: "/Navratri" },
      ],
    },
    {
      date: "25/09/2023",
      event: "Pharmacist Day",
      links: [
        { label: "Read More", url: "/Pharmaday" },
      ],
    },
    {
      date: "14/09/2023",
      event: "Orientation Program",
      links: [
        { label: "Read More", url: "/Orientation" },
      ],
    },
    {
      date: "05/09/2023",
      event: "Teacher's Day",
      links: [
        { label: "Read More", url: "/Teachers" },
      ],
    },
    {
      date: "21/03/2023",
      event: "Gudi Padwa Celebration",
      links: [
        { label: "Read More", url: "/Gudhipadwa" },
      ],
    },
  ];

  // Filter logic
  const filteredActivities =
    filter === "all"
      ? activities
      : activities.filter((item) => item.date.includes(filter));

  return (
    <div className="container mt-4 activity">
      <div className="d-flex flex-column align-items-center">
        <h2 className="text-custom-green text-center">Cocurricular & Extra Curricular Activities</h2>
        {/* Buttons */}
        <div className="mb-4">
          <button
            className="btn me-2"
            onClick={() => setFilter("all")}
          >
            All Activities
          </button>
          <button
            className="btn me-2"
            onClick={() => setFilter("2024")}
          >
            2024
          </button>
          <button
            className="btn"
            onClick={() => setFilter("2023")}
          >
            2023
          </button>
        </div>

        {/* Activity List */}
        <table className="table table-striped table border border-custom-green">
          <thead>
            <tr>
              <th>Date</th>
              <th>Event</th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            {filteredActivities.map((activity, index) => (
              <tr key={index}>
                <td>{activity.date}</td>
                <td>{activity.event}</td>
                <td>
                  {activity.links.map((link, idx) => (
                    <div key={idx}>
                      <a href={link.url} className="text-custom-green">
                        {link.label}
                      </a>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActivityList;
