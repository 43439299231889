import React from "react";





const WorldLeprosy = () => {

    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            World Leprosy day ( 30/01/2024)
            </h1>
            <p className="text-dark">
            World Leprosy Day, observed on the last Sunday of January each year, is a significant date dedicated to raising awareness about leprosy, also known as Hansen's disease, a chronic infectious disease caused by the bacterium Mycobacterium leprae. This day seeks to dispel myths and stigmas associated with the disease, advocate for the rights of those affected, and highlight the need for ongoing research and support to eliminate it. Despite being curable and having a treatment available since the 1940s, leprosy continues to affect hundreds of thousands of people worldwide, primarily in developing countries.
            </p>
            <p className="text-dark">
            World Leprosy Day was celebrated on 12/02/2024 at B.R.Harne college of pharmacy vangani,celebration was started by a marathon huge number of students has participated in the marathon First, second, third prize was declared awareness programme conducted at last programme was ended with refreshment and vote of thanks
            </p>
        </div>
    );
};



export default WorldLeprosy;