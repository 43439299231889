import React from "react";

// Import images and video related to the event
import img1 from './assets/Gud/pad1.jpeg';  // Video file
import img2 from './assets/Gud/pad2.jpeg';
import img3 from './assets/Gud/pad3.jpeg';

const Gudhipadwa = () => {
    const imageStyle = {
        width: '100%',
        // height: '500px', // Adjusted height to 300px
        objectFit: 'cover',
    };

    return (
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">Gudi Padwa Celebration (21/03/2023)</h1>
            <p className="text-dark">
            Gudi Padwa, also known as Ugadi, Yugadi, or Cheti Chand, marks the Marathi New Year and is celebrated on the first day of the Chaitra month. It symbolizes the beginning of a new year according to the Hindu lunisolar calendar.
            </p>
            <p className="text-dark">
            The celebration at B R Harne College of Pharmacy, Vangani, included a speech or interaction by the principal emphasizing the importance of Gudi Padwa. All teaching and non-teaching staff attended the event, and students were excited to participate and enjoy the festivities.
            </p>

            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                <img src={img1} alt="MUDRA Event 2" className="img-fluid" style={imageStyle} />

                </div>
                <div className="col-12 col-md-4 mb-3">
                    <img src={img2} alt="MUDRA Event 2" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <img src={img3} alt="MUDRA Event 3" className="img-fluid" style={imageStyle} />
                </div>



            </div>
        </div>
    );
};

export default Gudhipadwa;
