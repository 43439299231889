import React from "react";

const Form = () => {
  return (
    <div className="container" style={{ maxWidth: "100%", overflow: "hidden" }}>
      {/* <h1>Form</h1> */}
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSco_qe1YG1vgLkVOX6T3yTj2Q2vqzdfwwSDUu22Aolrz2IT8w/viewform?embedded=true"
        width="100%" // Full width
        height="1400" // Adjust the height to fit the entire form
        style={{
          border: "none", // Remove the border
          overflow: "hidden", // Hide any overflow
        }}
        title="Google Form"
      />
    </div>
  );
};

export default Form;
