import React from "react";

import './VisMis.css'



const VisMis = () => {
    return(
        <div className="container-fluid vismis">
            <div className="container vismis-parent">
            <div className="row visionmission pt-4 pb-4 text-light">
                    <div className="col-12 d-flex align-items-center flex-column">
                        <h4>Our Vision</h4>
                        <p className="text-center mt-2">"To foster our students’ enthusiasm towards developing successful careers".</p>

                    </div>
                    <div className="col-12 d-flex align-items-center flex-column">
                        <h4>Our Mission</h4>
                        <p className="text-center mt-2">"Our mission is to build the foundation of well- rounded, confident, and responsible Pharmacists who aspire to achieve their full potential"</p>

                    </div>
                </div>
            </div>
        </div>
    );
};


export default VisMis;