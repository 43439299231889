import React from "react";
import './cdc.css'

// Sample data for members
const cdcMembers = [
  { srNo: 1, name: "Hon. Shri. Mangesh B. Harne", designation: "Chairman" },
  { srNo: 2, name: "Mr. Rushikesh M. Harne", designation: "Member" },
  { srNo: 3, name: "Dr. Sankent Dharashivkar", designation: "Member (From Academics)" },
  { srNo: 4, name: "Mr. Vaman S. Yadav", designation: "Member" },
  { srNo: 5, name: "Mr. Mahesh D. Pawar", designation: "Member (From Industry)" },
  { srNo: 6, name: "Mr. Hemant V. Dhakne", designation: "Member" },
  { srNo: 7, name: "Mr. Maan C. Singh", designation: "Member" },
  { srNo: 8, name: "Mrs. Snehal S. Magar", designation: "Member" },
  { srNo: 9, name: "Dr. Mukund G. Tawar", designation: "Principal & Member Secretary" }
];

const CDC = () => {
  return (
    <div className="container mt-5 mb-5 cdc d-flex flex-column align-items-center">
      <h2 className="text-custom-green text-center cdc-text">
        Constitution of College Development Committee
      </h2>

      <table className="table table-striped border border-custom-green  table-bordered text-center">
        <thead className="thead-dark">
          <tr>
            <th>Sr. No</th>
            <th>Name</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          {cdcMembers.map((member) => (
            <tr key={member.srNo}>
              <td>{member.srNo}</td>
              <td>{member.name}</td>
              <td>{member.designation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CDC;
