import React from "react";

import img1 from './assets/lcp/lcp1.jpeg';
import img10 from './assets/lcp/lcp10.jpeg';
import img11 from './assets/lcp/lcp11.jpeg';
import img3 from './assets/lcp/lcp3.jpeg';
import img5 from './assets/lcp/lcp5.jpeg';
import img6 from './assets/lcp/lcp6.jpeg';
import img7 from './assets/lcp/lcp7.jpeg';
import img8 from './assets/lcp/lcp11.jpeg';



const Leprosy = () => {

    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };
    return(
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">
            Leprosy Awareness Program (12/02/2024)
            </h1>
            <p className="text-dark">
            Leprosy Awareness Program conducted by B.R. Harne College of Pharmacy in collaboration with the Maharashtra Government Health Department, Assistant Director of Health Services (Leprosy), Thane.
            </p>

            <div className="row">
                <div className="col-12 col-md-3 mb-4">
                    <img src={img1} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img10} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img11} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img5} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img6} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img7} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img8} alt="" className="img-fluid" style={imageStyle}/>

                </div>
                <div className="col-12 col-md-3 mb-4">
                    <img src={img3} alt="" className="img-fluid" style={imageStyle}/>

                </div>
            </div>

        </div>
    );
};



export default Leprosy;