import React from "react";


const Bpharmsyllabus = () => {
    return(
        <div className="container mt-5 mb-5 d-flex flex-column align-items-center">
            <h2 className="text-custom-green text-center bs-text mb-4">Syllabus of B.Pharm</h2>
            <div className="row bs-table">
            {/* <h1 class="text-custom-green">B.Pharm Syllabus: First Year</h1> */}

<table class="table table-striped border border-custom-green  table-bordered">
  <thead>
    <tr>
      <th>First Year Semester I</th>
      <th>First Year Semester II</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Human Anatomy and Physiology I – Theory</td>
      <td>1. Human Anatomy and Physiology II – Theory</td>
    </tr>
    <tr>
      <td>2. Pharmaceutical Analysis I – Theory</td>
      <td>2. Pharmaceutical Organic Chemistry I – Theory</td>
    </tr>
    <tr>
      <td>3. Pharmaceutics I – Theory</td>
      <td>3. Biochemistry – Theory</td>
    </tr>
    <tr>
      <td>4. Pharmaceutical Inorganic Chemistry – Theory</td>
      <td>4. Pathophysiology – Theory</td>
    </tr>
    <tr>
      <td>5. Communication skills – Theory</td>
      <td>5. Computer Applications in Pharmacy – Theory</td>
    </tr>
    <tr>
      <td>6. Remedial Biology/ Remedial Mathematics*</td>
      <td>6. Environmental sciences – Theory</td>
    </tr>
    <tr>
      <td>7. Human Anatomy and Physiology – Practical</td>
      <td>7. Human Anatomy and Physiology II – Practical</td>
    </tr>
    <tr>
      <td>8. Pharmaceutical Analysis I – Practical</td>
      <td>8. Pharmaceutical Organic Chemistry I – Practical</td>
    </tr>
    <tr>
      <td>9. Pharmaceutics I – Practical</td>
      <td>9. Biochemistry – Practical</td>
    </tr>
    <tr>
      <td>10. Pharmaceutical Inorganic Chemistry – Practical</td>
      <td>10. Computer Applications in Pharmacy – Practical</td>
    </tr>
    <tr>
      <td>11. Communication skills – Practical</td>
      <td>--</td>
    </tr>
    <tr>
      <td>12. Remedial Biology – Practical*</td>
      <td>--</td>
    </tr>
  </tbody>
</table>



{/* <h1 class="text-custom-green">B.Pharm Syllabus: Second Year</h1> */}

<table class="table table-bordered border border-custom-green ">
  <thead>
    <tr>
      <th>Second Year Semester III</th>
      <th>Second Year Semester IV</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Pharmaceutical Organic Chemistry II – Theory</td>
      <td>1. Pharmaceutical Organic Chemistry III – Theory</td>
    </tr>
    <tr>
      <td>2. Physical Pharmaceutics I – Theory</td>
      <td>2. Medicinal Chemistry I – Theory</td>
    </tr>
    <tr>
      <td>3. Pharmaceutical Microbiology – Theory</td>
      <td>3. Physical Pharmaceutics II – Theory</td>
    </tr>
    <tr>
      <td>4. Pharmaceutical Engineering – Theory</td>
      <td>4. Pharmacology I – Theory</td>
    </tr>
    <tr>
      <td>5. Pharmaceutical Organic Chemistry II – Practical</td>
      <td>5. Pharmacognosy I – Theory</td>
    </tr>
    <tr>
      <td>6. Physical Pharmaceutics I – Practical</td>
      <td>6. Medicinal Chemistry I – Practical</td>
    </tr>
    <tr>
      <td>7. Pharmaceutical Microbiology – Practical</td>
      <td>7. Physical Pharmaceutics II – Practical</td>
    </tr>
    <tr>
      <td>8. Pharmaceutical Engineering – Practical</td>
      <td>8. Pharmacology I – Practical</td>
    </tr>
  </tbody>
</table>




{/* <h1 class="text-custom-green">B.Pharm Syllabus: Third and Final Year</h1> */}

{/* <!-- Third Year Syllabus --> */}
{/* <h2>Third Year</h2> */}
<table class="table table-bordered border border-custom-green">
  <thead>
    <tr>
      <th>Third Year Semester V</th>
      <th>Third Year Semester VI</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Medicinal Chemistry II – Theory</td>
      <td>1. Medicinal Chemistry III – Theory</td>
    </tr>
    <tr>
      <td>2. Industrial Pharmacy I – Theory</td>
      <td>2. Pharmacology III – Theory</td>
    </tr>
    <tr>
      <td>3. Pharmacology II – Theory</td>
      <td>3. Herbal Drug Technology – Theory</td>
    </tr>
    <tr>
      <td>4. Pharmacognosy and Phytochemistry II – Theory</td>
      <td>4. Biopharmaceutics and Pharmacokinetics – Theory</td>
    </tr>
    <tr>
      <td>5. Pharmaceutical Jurisprudence – Theory</td>
      <td>5. Pharmaceutical Biotechnology – Theory</td>
    </tr>
    <tr>
      <td>6. Industrial Pharmacy I – Practical</td>
      <td>6. Quality Assurance – Theory</td>
    </tr>
    <tr>
      <td>7. Pharmacology II – Practical</td>
      <td>7. Medicinal Chemistry III – Practical</td>
    </tr>
    <tr>
      <td>8. Pharmacognosy and Phytochemistry II – Practical</td>
      <td>8. Pharmacology III – Practical</td>
    </tr>
    <tr>
      <td>--</td>
      <td>9. Herbal Drug Technology – Practical</td>
    </tr>
  </tbody>
</table>

{/* <!-- Final Year Syllabus --> */}
{/* <h2>Final Year</h2> */}
<table class="table table-bordered border border-custom-green ">
  <thead>
    <tr>
      <th>Final Year Semester VII</th>
      <th>Final Year Semester VIII</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Instrumental Methods of Analysis – Theory</td>
      <td>1. Biostatistics and Research Methodology</td>
    </tr>
    <tr>
      <td>2. Industrial Pharmacy II – Theory</td>
      <td>2. Social and Preventive Pharmacy</td>
    </tr>
    <tr>
      <td>3. Pharmacy Practice – Theory</td>
      <td>3. Project Work</td>
    </tr>
  </tbody>
</table>

{/* <!-- Electives for Semester VIII --> */}
<h3 className="text-custom-green mt-4 mb-4">Electives Offered in Semester VIII</h3>
<table class="table table-bordered border border-custom-green ">
  <thead>
    <tr>
      <th>Final Year Semester VII</th>
      <th>Final Year Semester VIII</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4. Novel Drug Delivery System – Theory</td>
      <td>1. Pharma Marketing Management</td>
    </tr>
    <tr>
      <td>5. Instrumental Methods of Analysis – Practical</td>
      <td>2. Pharmaceutical Regulatory Science</td>
    </tr>
    <tr>
      <td>6. Practice School</td>
      <td>3. Dietary Supplements and Nutraceuticals – Theory</td>
    </tr>
    <tr>
      <td>--</td>
      <td>4. Pharmaceutical Product Development – Theory</td>
    </tr>
  </tbody>
</table>


{/* <!-- Third Year Syllabus --> */}
{/* <h2>Third Year</h2>
<table class="table table-bordered">
  <thead>
    <tr>
      <th>Semester V</th>
      <th>Semester VI</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Medicinal Chemistry II – Theory</td>
      <td>1. Medicinal Chemistry III – Theory</td>
    </tr>
    <tr>
      <td>2. Industrial Pharmacy I – Theory</td>
      <td>2. Pharmacology III – Theory</td>
    </tr>
    <tr>
      <td>3. Pharmacology II – Theory</td>
      <td>3. Herbal Drug Technology – Theory</td>
    </tr>
    <tr>
      <td>4. Pharmacognosy and Phytochemistry II – Theory</td>
      <td>4. Biopharmaceutics and Pharmacokinetics – Theory</td>
    </tr>
    <tr>
      <td>5. Pharmaceutical Jurisprudence – Theory</td>
      <td>5. Pharmaceutical Biotechnology – Theory</td>
    </tr>
    <tr>
      <td>6. Industrial Pharmacy I – Practical</td>
      <td>6. Quality Assurance – Theory</td>
    </tr>
    <tr>
      <td>7. Pharmacology II – Practical</td>
      <td>7. Medicinal Chemistry III – Practical</td>
    </tr>
    <tr>
      <td>8. Pharmacognosy and Phytochemistry II – Practical</td>
      <td>8. Pharmacology III – Practical</td>
    </tr>
    <tr>
      <td>--</td>
      <td>9. Herbal Drug Technology – Practical</td>
    </tr>
  </tbody>
</table> */}

{/* <!-- Final Year Syllabus --> */}
{/* <h2>Final Year</h2>
<table class="table table-bordered">
  <thead>
    <tr>
      <th>Semester VII</th>
      <th>Semester VIII</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1. Instrumental Methods of Analysis – Theory</td>
      <td>1. Biostatistics and Research Methodology</td>
    </tr>
    <tr>
      <td>2. Industrial Pharmacy II – Theory</td>
      <td>2. Social and Preventive Pharmacy</td>
    </tr>
    <tr>
      <td>3. Pharmacy Practice – Theory</td>
      <td>3. Project Work</td>
    </tr>
  </tbody>
</table> */}

{/* <!-- Electives for Semester VIII --> */}
{/* <h3>Electives Offered in Semester VIII</h3>
<table class="table table-bordered">
  <thead>
    <tr>
      <th>Semester VII</th>
      <th>Semester VIII</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>4. Novel Drug Delivery System – Theory</td>
      <td>1. Pharma Marketing Management</td>
    </tr>
    <tr>
      <td>5. Instrumental Methods of Analysis – Practical</td>
      <td>2. Pharmaceutical Regulatory Science</td>
    </tr>
    <tr>
      <td>6. Practice School</td>
      <td>3. Dietary Supplements and Nutraceuticals – Theory</td>
    </tr>
    <tr>
      <td>--</td>
      <td>4. Pharmaceutical Product Development – Theory</td>
    </tr>
  </tbody>
</table> */}



            </div>

        </div>
    );
};



export default Bpharmsyllabus;