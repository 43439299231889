import React from "react";
import './Contact.css';

const Contact = () => {
    return (
        <div className="container-fluid contact" style={{ backgroundColor: '#e5e8dc' }}>
            <div className="container d-flex flex-column align-items-center py-5">
                <h2 className="text-custom-green text-center contact-text">Contact Us</h2>
                <div className="row mt-4 d-flex">
                    {/* Google Map Embed */}
                    <div className="col-12 col-md-6 d-flex justify-content-center mb-4 mb-md-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3250.102619174963!2d73.31090186335308!3d19.119178546783836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7be1ef98ff0d7%3A0x13bbcb218bddcf1e!2sB.%20R.%20Harne%20College%20of%20Pharmacy!5e0!3m2!1sen!2sin!4v1733318313576!5m2!1sen!2sin"
                            width="100%"
                            height="300"
                            style={{ border: '0', borderRadius: '8px' }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Google Maps"
                        ></iframe>
                    </div>

                    {/* Contact Details */}
                    <div className="col-12 col-md-6">
                        <h3 className="text-custom-green text-start">Say Hello</h3>
                        <p>
                            <strong>Email:</strong> brharnepharmacy@gmail.com<br />
                            <strong>Phone:</strong> 7218113531 / 9665000861
                        </p>

                        <h3 className="text-custom-green text-start">Find Us</h3>
                        <p>Karav, Vangani (W), Ambernath, Dist : Thane - 421503</p>

                        <h3 className="text-custom-green text-start">Social Networks</h3>
                        <div className="d-flex gap-3 justify-content-start">
                            <a href="https://www.facebook.com/brhcop/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-facebook fa-1x text-dark"></i>
                            </a>
                            <a href="https://www.google.com/search?q=B.+R.+Harne+College+Of+Pharmacy" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-google fa-1x text-dark"></i>
                            </a>
                            <a href="https://www.instagram.com/brharnecollegeofpharmacy/" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-instagram fa-1x text-dark"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/b-r-harne-college-of-pharmacy-536127325?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin fa-1x text-dark"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
