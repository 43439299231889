import React from "react";

// Import images and video related to the event
import img1 from './assets/tea/tea2.mp4';  // Video file
import img2 from './assets/tea/tea.jpg';
import img3 from './assets/tea/tea1.jpg';

const Teachers = () => {
    const imageStyle = {
        width: '100%',
        // height: '500px', // Adjusted height to 300px
        objectFit: 'cover',
    };

    return (
        <div className="container mt-5 mb-5">
            <h1 className="text-custom-green text-center mb-4">Teachers Day ( 05/09/2023)</h1>
            <p className="text-dark">
            Teacher's Day is a globally celebrated occasion dedicated to honoring the invaluable contributions of educators to society. It serves as a reminder of the profound impact teachers have on shaping the minds and futures of countless individuals. Whether in schools, colleges, universities, or other educational settings, teachers play a pivotal role in imparting knowledge, fostering critical thinking, and instilling values in their students.
            </p>
            <p className="text-dark">
            The origins of Teacher's Day vary across different countries, often rooted in historical events or the recognition of influential educators. However, regardless of the date or context, Teacher's Day universally symbolizes gratitude and appreciation for the tireless efforts and dedication of teachers worldwide.
            </p>
            <p className="text-dark">
            Teachers day celebration was held on 5/09/2023 it was started by saraswati puja ,speech, dancing and singing performance of students and there were various games organize by students for teacchers.
            </p>

            <div className="row">
                <div className="col-12 col-md-4 mb-3">
                    {/* Display video file (MP4) */}
                    <video width="100%" controls style={imageStyle}>
                        <source src={img1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <img src={img2} alt="MUDRA Event 2" className="img-fluid" style={imageStyle} />
                </div>
                <div className="col-12 col-md-4 mb-3">
                    <img src={img3} alt="MUDRA Event 3" className="img-fluid" style={imageStyle} />
                </div>



            </div>
        </div>
    );
};

export default Teachers;
