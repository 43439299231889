import React from "react";

import './bpharmcourse.css'



const Bpharmcourse = () => {
    return(
        <div className="container bpharmcourse mt-3 mb-5 d-flex flex-column align-items-center">
          <h2 className="course-head text-center">B.Pharm Course</h2>

          <div className="course-content">


            <h2 className="text-custom-green text-start">Eligibility Criteria</h2>

<h4 className="text-dark">Maharashtra State Candidature Candidates</h4>
<ul className="text-dark">
  <li>The candidate should be an Indian National</li>
  <li>Passed HSC or its equivalent examination with Physics and Chemistry as compulsory subjects along with one of the Mathematics or Biology and obtained at least 45% marks (at least 40% marks in case of candidates of Backward class categories and Persons with Disability belonging only to Maharashtra State) in the above subjects taken together</li>
  <li>Should have appeared in all the subjects and should obtain non-zero scores in CET</li>
  <li>Should have registered for the Centralized admission process (CAP)</li>
</ul>

<h4 className="text-dark">For All India Candidature Candidates</h4>
<ul className="text-dark">
  <li>Apart from the aforementioned criteria, the candidate should obtain a non-zero positive score in NEET or the candidate should have appeared in all the subjects in CET and should obtain non-zero scores in CET conducted by the competent authority. However, preference shall be given to the candidate obtaining non-zero positive scores in NEET over the candidates who obtained non-zero scores in CET.</li>
</ul>

<h2 className="text-custom-green text-start">Enrollment of student at University of Mumbai</h2>
<p className="text-dark">
  The students are required to follow the instructions given by the University of Mumbai for enrollment purposes. Website address is: <a href="http://mum.digitaluniversity.ac" className="text-dark">http://mum.digitaluniversity.ac</a>. Refer to the “Download Section”, click on “more” and download “Pre-admission online registration process for students” for detailed instructions for filling online enrollment form. The Enrollment of students to University of Mumbai is compulsory.
</p>

<h2 className="text-custom-green text-start">Documents to be submitted for admission to F.Y. B. Pharm. (ARC/FC verified copies only)*</h2>
<ul className="text-dark">
  <li>MHT-CET score card 2022</li>
  <li>Mark sheets of Std X & XII & Passing Certificate</li>
  <li>College Leaving Certificate / Transfer Certificate</li>
  <li>Domicile Certificate/Birth Certificate</li>
  <li>Income certificate for TFWS Scheme/ scholarship</li>
  <li>Physically Handicapped/Disability certificate (with a Degree of handicap)</li>
  <li>Gap certificate (if applicable)</li>
  <li>Caste certificate and Caste Validity certificate</li>
  <li>Non-Creamy layer certificate</li>
  <li>Passport-size photographs (4 copies)</li>
  <li>Migration certificate for students other than Home University/Maharashtra State Board</li>
  <li>Affidavit of minority status (Mandatory for Minority Students)</li>
</ul>
<p className="text-dark">
  *All these are to be submitted as .pdf files and as three hard copies.
</p>

<h2 className="text-custom-green text-start">Scheme of Assessment</h2>
<p className="text-dark"><strong>Note:</strong> In Sessional (theory/practical), two sessionals to be conducted within each semester, average of best two to be taken</p>

<table className="table table-bordered text-dark text-center">
  <thead>
    <tr>
      <th></th>

      <th>Attendance</th>
      <th>Assignment/Tests/Journal</th>
      <th>Student-Teacher Interaction/Activities</th>
      <th>Total Continuous Assessment Marks</th>
      <th>Total Each Subject</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Theory</td>

      <td>10</td>
      <td>15</td>
      <td>25</td>
      <td>75</td>
      <td>100</td>
    </tr>
    <tr>
        <td>Practical</td>
      <td>5</td>
      <td>10</td>
      <td>15</td>
      <td>35</td>
      <td>50</td>
    </tr>
    <tr>
        <td>Sem. VII Practice school</td>
      <td>25</td>
      <td>--</td>
      <td>25</td>
      <td>125</td>
      <td>150</td>
    </tr>
    <tr>
        <td>Sem. VIII Project Work</td>
      <td>--</td>
      <td>--</td>
      <td>--</td>
      <td>150</td>
      <td>150</td>
    </tr>
  </tbody>
</table>

{/* <h2 className="text-custom-green">Sessional Exam (Theory/Practical)</h2> */}
<table className="table table-bordered text-dark">
  <thead>
    <tr>
        <th></th>
        <th>Sessional Exam (Theory/Practical)</th>
      <th>Continuous Assessment</th>
      <th>Total Internal Marks</th>
      <th>Semester Exam (Theory/Practical)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
        <td>Theory</td>
      <td>4</td>
      <td>3</td>
      <td>3</td>
      <td>10</td>
    </tr>
    <tr>
        <td>Practical</td>
      <td>2</td>
      <td>--</td>
      <td>3</td>
      <td>5</td>
    </tr>
  </tbody>
</table>
</div>

        </div>
    );
};




export default Bpharmcourse;