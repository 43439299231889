import React from 'react';
import './Notif.css';
import blinkImage from './assets/new.gif'; // Replace with your image path

const Notif = () => {
  return (
    <div className="notification-container">
      <div className="notification-content">
        <img src={blinkImage} alt="Blinking Icon" className="blinking-image" />
        <div className="notification-text">
          <a href="/Form" className="notification-link">BRHCOP Admission Enquiry 2024-25</a>
        <img src={blinkImage} alt="Blinking Icon" className="blinking-image" />

          <span className="separator">|</span>
        <img src={blinkImage} alt="Blinking Icon" className="blinking-image" />

          <a href="https://chat.whatsapp.com/IQDhab16qHsArgG2H5d9FQ" className="notification-link">Join Admission Inquiry Group</a>
        </div>
        <img src={blinkImage} alt="Blinking Icon" className="blinking-image" />

      </div>
    </div>
  );
};

export default Notif;
