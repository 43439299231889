import React from "react";

import bd1 from './assets/BD/blo1.jpeg';
import bd2 from './assets/BD/blo2.jpeg';
import bd3 from './assets/BD/blo3.jpeg';
import bd4 from './assets/BD/blo4.jpeg';

const Blooddon = () => {

    // Define the common image styles
    const imageStyle = {
        width: '100%',
        height: '400px',
        objectFit: 'cover',
    };

    return (
        <div className="container mb-5 mt-5">
            <h1 className="text-custom-green text-center mb-4">Blood Donation (13/03/2024)</h1>
            <div className="row">
                <div className="col-12 col-md-3 mb-3">
                    <img src={bd1} alt="Blood Donation 1" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={bd2} alt="Blood Donation 2" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={bd3} alt="Blood Donation 3" style={imageStyle} />
                </div>
                <div className="col-12 col-md-3 mb-3">
                    <img src={bd4} alt="Blood Donation 4" style={imageStyle} />
                </div>
            </div>
        </div>
    );
};

export default Blooddon;
